import {
    CountryListPage,
    CountrySummaries,
    ClientListPage,
    ClientSummaries,
    FrameworkDetailFilters,
    DropdownCountry,
    CountryCSVParams,
    ClientCSVParams
} from '../frameworkTypes';
import { SortDirection, TableCellTypes } from '../../../components/Table/types';
import axios from 'axios';
import { downloadFileFromData } from '../../../utils/helpers';
import { MutationFunction } from '@apollo/client';
import { getCookie } from '../../../utils/cookie-storage';

export const setTableRowsWithCounrty = (
    allSelectedFrmByCountries: number[],
    handleFrmByCountriesToggle: (cellID: number) => void,
    countrySummaries?: CountryListPage
) => {
    return (
        countrySummaries &&
        countrySummaries.items.map((item: CountrySummaries) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.countryIsoCode,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrmByCountries.includes(
                            item.id
                        ),
                        actionFunc: () => handleFrmByCountriesToggle(item.id),
                        cellID: item.id
                    }
                ]
            };
        })
    );
};

export const setTableRowsWithClient = (
    allSelectedFrmByClients: number[],
    handleFrmByClientsToggle: (cellID: number) => void,
    clientSummaries?: ClientListPage
) => {
    return (
        clientSummaries &&
        clientSummaries.items.map((item: ClientSummaries) => {
            return {
                tableCells: [
                    {
                        cellName: item.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: item.countryIsoCode,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrmByClients.includes(item.id),
                        actionFunc: () => handleFrmByClientsToggle(item.id),
                        cellID: item.id
                    }
                ]
            };
        })
    );
};

export const getCountriesTableHeader = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'Country name',
        isHeaderSortable: false
    },
    {
        headerName: 'Country code',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('isoCode')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const getClientsTableHeader = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'Company name',
        isHeaderSortable: false
    },
    {
        headerName: 'Country code',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: ()=>createSortHandler('countryIsoCode')
    },
    {
        headerName: 'Framework access',
        isHeaderSortable: false
    }
];

export const getFilters = (
    selectedCountries: string,
    allCountries: DropdownCountry[],
    frameworkAccess: string,
    setFilters: Function
) => {
    const filter: FrameworkDetailFilters = {
        countryIds: undefined,
        frameworkEnabled: undefined
    };

    if (selectedCountries !== 'All') {
        const chosenCountry = allCountries.find(
            (selCountry: DropdownCountry) =>selCountry.value?selCountry.value===selectedCountries:selCountry.name === selectedCountries
        );
        if (chosenCountry) {
            filter.countryIds = [chosenCountry.id];
        }
    }

    filter.frameworkEnabled =
        frameworkAccess === 'Enabled'
            ? true
            : frameworkAccess === 'Disabled'
            ? false
            : undefined;
    setFilters(filter);
};
export const countriesDTO = (
    allInteractedFrmByCountries: number[],
    allSelectedFrmByCountries: number[]
) => {
    return allInteractedFrmByCountries.map((frId: number) => {
        return {
            id: frId,
            frameworkEnabled: allSelectedFrmByCountries.includes(frId)
        };
    });
};
export const clientsDTO = (
    allInteractedFrmByClients: number[],
    allSelectedFrmByClients: number[]
) => {
    return allInteractedFrmByClients.map((frId: number) => {
        return {
            id: frId,
            frameworkEnabled: allSelectedFrmByClients.includes(frId)
        };
    });
};

export const saveHandler = (
    allInteractedFrmByCountries: number[],
    allInteractedFrmByClients: number[],
    editFramework: MutationFunction,
    frameworkId: string,
    allSelectedFrmByCountries: number[],
    allSelectedFrmByClients: number[],
    setOpenSuccessModal:Function,
    setAllInteractedFrmByCountries:(frameworkByCountryIds: number[]) => void,
    setAllInteractedFrmByClients: (frameworkByClientIds: number[]) => void,
) => {
    if (
        allInteractedFrmByCountries.length === 0 &&
        allInteractedFrmByClients.length ===0
    ) {
        return;
    }
const at1= clientsDTO(
    allInteractedFrmByClients,
    allSelectedFrmByClients
)
const at2=countriesDTO(
    allInteractedFrmByCountries,
    allSelectedFrmByCountries
)
    editFramework({
        variables: {
            data: {
                frameworkId:parseInt(frameworkId),
                clients: clientsDTO(
                    allInteractedFrmByClients,
                    allSelectedFrmByClients
                ),
                countries: countriesDTO(
                    allInteractedFrmByCountries,
                    allSelectedFrmByCountries
                )
            }
        }
    })
        .then(() => {
            setAllInteractedFrmByCountries([]);
            setAllInteractedFrmByClients([]);
            setOpenSuccessModal(true);
        })
        .catch(err => {
            console.log(err);
        });
};

export const exportCountrySummariesByFrameworkCSV = (setSpinner: Function, params: CountryCSVParams) => {
    setSpinner(true);
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/framework/'+params.frameworkId+'/country/summaries/csv',
            { frameworkEnabled: params.frameworkEnabled, countryIds: params.countryIds },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'CountriesByFramework Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};

export const exportCompanySummariesByFrameworkCSV = (setSpinner: Function, params: ClientCSVParams) => {
    setSpinner(true);
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/framework/'+params.frameworkId+'/client/summaries/csv',
            { frameworkEnabled: params.frameworkEnabled,countryIds:params.countryIds },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'CompaniesByFramework Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};
