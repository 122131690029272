



import { AxiosError } from 'axios';
import { UsersListPage, UserSummary, Country, Filters, ClientListPage, CountryListPage, ClientSummaries, CountrySummaries } from './userTypes';
import { SortDirection, TableCellTypes } from '../../components/Table/types';
import Router from '../../routes/router';
import axios from 'axios';
import {convertPermissionLabels, downloadFileFromData} from '../../utils/helpers';
import * as messages from '../../utils/Constant/messages';
import { getCookie } from '../../utils/cookie-storage';

const handleCTA = (id: number, name: string, role: string) => {
    Router.goToUser({ userId: id.toString(), userName: name, userRole: role });
};

export const TabOptions = {
    COUNTRIES: 'Countries',
    COMPANIES: 'Companies'
};

export const tabLabels = [TabOptions.COUNTRIES, TabOptions.COMPANIES];

const editUsersDTO = (
    allInteractedUsers: number[],
    allSelectedUsers: number[]
) => {
    return allInteractedUsers.map((item: number) => {
        return {
            id: item,
            userEnabled: allSelectedUsers.includes(item)
        };
    });
}

export const saveHandler = (
    allInteractedUsers: number[],
    editUsers: Function,
    allSelectedUsers: number[],
    setOpenSuccessModal: Function,
    setIsModalInternal: Function,
    setAllInteractedUsers: (userIds: number[]) => void,
    setOpenFailureModal: Function,
    setIsFailureModalInternal: Function,
    setUserDisableErrorDialog: Function,
    setUserDisableErrorMessage: Function

) => {
    if (allInteractedUsers.length === 0) {
        return;
    }

    const usersDTO = editUsersDTO(
        allInteractedUsers,
        allSelectedUsers
    );

    editUsers({
        variables: {
            data: usersDTO
        }
    })
        .then(() => {
            setAllInteractedUsers([]);
            setIsModalInternal(true)
            setOpenSuccessModal(true);
        })
        .catch((err: AxiosError) => {
            if(err.message.includes(messages.ERROR_USER_RM_DISABLED))
            {
                console.log(err);
                setAllInteractedUsers([]);
                setIsFailureModalInternal(true)
                setOpenFailureModal(true);
            }
            else
            {
                console.log(err);
                setUserDisableErrorDialog(true);
                setUserDisableErrorMessage(err.message);
            }

        });
};

export const setTableRows = (
    usersListPage: UsersListPage,
    handleToggleActivateChange: (cellID: number) => void,
    allSelectedCompanies: number[],
    role?: string
) => {
    return (
        usersListPage &&
        usersListPage.items.map((user: UserSummary) => {
            return {
                tableCells: [
                    {
                        cellName: user.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: user.email,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: user.primaryCountry,
                        isCellSortable: false,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: convertPermissionLabels(user.displayRole),
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: user.countryIsoCode,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedCompanies.includes(user.id),
                        actionFunc: () => handleToggleActivateChange(user.id),
                        cellID: user.id,
                        hideCell: (user.role === 'ADMIN' || user.role === 'STANDARD') && role === 'STANDARD'
                    },
                    {
                        isCellSortable: false,
                        cellType: 'action' as TableCellTypes,
                        actionFunc: () => handleCTA(user.id, user.name, user.role)
                    }
                ]
            };
        })
    );
};

export const getHeaderData = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'User name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('name')
    },{
        headerName: 'Email address',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('email')
    },
    {
        headerName: 'PwC office (country)',
        isHeaderSortable: false
    },
    {
        headerName: 'Permissions',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('displayRole')
    },
    {
        headerName: 'Enabled countries',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('countryIsoCode')
    },
    {
        headerName: 'Status',
        isHeaderSortable: false
    },
    {
        headerName: 'View',
        isHeaderSortable: false
    }
];

export const disabledEnabledDropdownValues = [
    {
        id:0,
        name:'All'
    },
    {
        id:1,
        name:'Disabled'
    },
    {
        id:2,
        name:'Enabled'
    }
];

export const userPermissionsValues = [
    {
        id:0,
        name:'All'
    },
    {
        id:1,
        name:'ADMIN'
    },
    {
        id:2,
        name:'STANDARD'
    },
    {
        id:3,
        name:'EXTERNAL'
    }
];

export const exportUserListCSV = (setSpinner: Function, params: Filters) => {
    setSpinner(true);
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/user/summaries/csv',
            { role: (params.role?.length ? [params.role] : undefined), userEnabled: params.userEnabled,countryIds:params.countryIds,primaryCountry:params.primaryCountry },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'Users Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
    };

export const getFilters = (
    selectedCountry: string,
    allCountries: Country[],
    selectedPermission: string,
    selectedStatus: string,
    selectedPrimaryCountry:string,
    setPage: Function,
    setFilters: Function
) => {
    const filter: Filters = {
        countryIds: undefined,
        userEnabled: undefined,
        role: undefined,
        primaryCountry:undefined
    };

    if (selectedCountry !== 'All') {
        const chosenCountry = allCountries.find((selCountry: Country) => selCountry.name === selectedCountry);
        if (chosenCountry) {
            filter.countryIds = [chosenCountry.id];
        }
    }

    filter.role = selectedPermission === 'All' ? undefined : selectedPermission;
    filter.userEnabled = selectedStatus === 'Disabled' ? false : selectedStatus === 'Enabled' ? true : undefined;
    filter.primaryCountry = selectedPrimaryCountry === 'All' ? undefined : selectedPrimaryCountry;
    setPage(0);
    setFilters(filter);
};

export const getClientsTableHeader = (
    createSortHandler: (property: string) => void,
    role: string,
    moderatorRole?: string,
    flag?:string
) => {
    if(role === 'EXTERNAL' || (moderatorRole !=='ADMIN' && role === 'ADMIN')) {
        return([
            {
                headerName: 'Company name',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>flag === 'add' ? createSortHandler('name'):createSortHandler('companyName')
            },
            {
                headerName: 'Country code',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>flag === 'add' ? createSortHandler('countryIsoCode'):createSortHandler('countryCode')
            }
        ]);
    } else {
        return([
            {
                headerName: 'Company name',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>flag === 'add' ? createSortHandler('name'):createSortHandler('companyName')
            },
            {
                headerName: 'Country code',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>flag === 'add' ? createSortHandler('countryIsoCode'):createSortHandler('countryCode')
            },
            {
                headerName: 'User access',
                isHeaderSortable: false
            }
        ]);
    }
};

export const getCountriesTableHeader = (
    createSortHandler: (property: string) => void,
    role: string,
    flag?: string,
    moderatorRole?: string
) => {
    if(role === 'EXTERNAL' || (moderatorRole !=='ADMIN' && role === 'ADMIN')){
        return [
            {
                headerName: 'Country name',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: () => flag === 'add' ? createSortHandler('name') : createSortHandler('countryName')
            },
            {
                headerName: 'Country code',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>createSortHandler('countryCode')
            },
            {
                headerName: 'Create campaigns',
                isHeaderSortable: false,
            },
        ];
    } else if(moderatorRole !== 'ADMIN'){
        return [
            {
                headerName: 'Country name',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: () => flag === 'add' ? createSortHandler('name') : createSortHandler('countryName')
            },
            {
                headerName: 'Country code',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>createSortHandler('countryCode')
            },
            {
                headerName: 'Create campaigns',
                isHeaderSortable: false,
            },
            {
                headerName: 'User access',
                isHeaderSortable: false
            }
        ];
    } else {
        return [
            {
                headerName: 'Country name',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: () => flag === 'add' ? createSortHandler('name') : createSortHandler('countryName')
            },
            {
                headerName: 'Country code',
                isHeaderSortable: true,
                sortDirection: 'asc' as SortDirection,
                sortingFunc: ()=>createSortHandler('countryCode')
            },
            {
                headerName: 'Country admin',
                isHeaderSortable: false,
            },
            {
                headerName: 'Create campaigns',
                isHeaderSortable: false,
            },
            {
                headerName: 'User access',
                isHeaderSortable: false
            }
        ];
    }
};

export const setTableRowsWithClient = (
    clientSummaries: ClientListPage,
    allSelectedClientUserEnabled:number[],
    handleClientUserEnableToggle:(cellID: number) => void,
    role: string,
    moderatorRole?: string
) => {
    if(role === 'EXTERNAL' || (moderatorRole !=='ADMIN' && role === 'ADMIN')) {
        return (
            clientSummaries &&
            clientSummaries.items.map((item: ClientSummaries) => {
                return {
                    tableCells: [
                        {
                            cellName: item.clientName,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            cellName: item.countryIsoCode,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        }
                    ]
                };
            })
        );
    } else {
        return (
            clientSummaries &&
            clientSummaries.items.map((item: ClientSummaries) => {
                return {
                    tableCells: [
                        {
                            cellName: item.clientName,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            cellName: item.countryIsoCode,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedClientUserEnabled.includes(item.clientId),
                            actionFunc: () => handleClientUserEnableToggle(item.clientId),
                            cellID: item.clientId
                        }
                    ]
                };
            })
        );
    }
};

export const setTableRowsWithCounrty = (
    countrySummaries: CountryListPage,
    allSelectedIsAdmin: number[],
    handleCountryAdminToggle: (cellID: number) => void,
    allSelectedCreateCampaigns:number[],
    handleCreateCampignToggle:(cellID: number) => void,
    allSelectedCountryUserEnabled:number[],
    handleCountryUserEnabledToggle:(cellID: number) => void,
    role: string,
    moderatorRole?: string
) => {
    if(role === 'EXTERNAL' || (moderatorRole !=='ADMIN' && role === 'ADMIN')){
        return (
            countrySummaries &&
            countrySummaries.items.map((item: CountrySummaries) => {
                return {
                    tableCells: [
                        {
                            cellName: item.name,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            cellName: item.isoCode,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedCreateCampaigns.includes(item.id),
                            actionFunc: () => handleCreateCampignToggle(item.id),
                            cellID: item.id
                        },
                    ]
                };
            })
        );
    } else if(moderatorRole !== 'ADMIN'){
        return (
            countrySummaries &&
            countrySummaries.items.map((item: CountrySummaries) => {
                return {
                    tableCells: [
                        {
                            cellName: item.name,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            cellName: item.isoCode,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedCreateCampaigns.includes(item.id),
                            actionFunc: () => handleCreateCampignToggle(item.id),
                            cellID: item.id
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedCountryUserEnabled.includes(item.id),
                            actionFunc: () => handleCountryUserEnabledToggle(item.id),
                            cellID: item.id
                        }
                    ]
                };
            })
        );
    } else {
        return (
            countrySummaries &&
            countrySummaries.items.map((item: CountrySummaries) => {
                return {
                    tableCells: [
                        {
                            cellName: item.name,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            cellName: item.isoCode,
                            isCellSortable: true,
                            cellType: 'string' as TableCellTypes
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Yes',
                                falseLabel: 'No'
                            },
                            currentState: allSelectedIsAdmin.includes(item.id),
                            actionFunc: () => handleCountryAdminToggle(item.id),
                            cellID: item.id
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedCreateCampaigns.includes(item.id),
                            actionFunc: () => handleCreateCampignToggle(item.id),
                            cellID: item.id
                        },
                        {
                            isCellSortable: false,
                            cellType: 'boolean' as TableCellTypes,
                            toggleLabels: {
                                trueLabel: 'Enabled',
                                falseLabel: 'Disabled'
                            },
                            currentState: allSelectedCountryUserEnabled.includes(item.id),
                            actionFunc: () => handleCountryUserEnabledToggle(item.id),
                            cellID: item.id
                        }
                    ]
                };
            })
        );
    }
};

export const createTempCountryUserEnabled = (
    allSelectedCountryUserEnabled: number[],
    allInteractedCountryUserEnabled: number[]
) => {
    return allInteractedCountryUserEnabled.map((countryId: number) => {
        return {
            countryId,
            countryUserEnabled: allSelectedCountryUserEnabled.includes(countryId)
        };
    });
};

export const createTempClients = (
    allInteractedClientUserEnabled: number[],
    allSelectedClientUserEnabled: number[]
) => {
    return allInteractedClientUserEnabled.map((id: number) => {
        return {
            clientId: id,
            clientUserEnabled: allSelectedClientUserEnabled.includes(id)
        };
    });
};

export const createTempCountries = (
    allInteractedIsAdmin: number[],
    allInteractedCreateCampaigns: number[],
    allInteractedCountryUserEnabled: number[],
    allSelectedIsAdmin: number[],
    allSelectedCreateCampaigns: number[],
    allSelectedCountryUserEnabled: number[],
    countrySummaries?: CountryListPage
) => {
    const mergedArray = [...allInteractedIsAdmin, ...allInteractedCreateCampaigns, ...allInteractedCountryUserEnabled];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((id: number) => {
        let campaignCreateEnabled, userEnabled, isCountryAdmin;
        if (allInteractedCountryUserEnabled.includes(id)) {
            userEnabled = allSelectedCountryUserEnabled.includes(id);
        } else {
            if (!!countrySummaries) {
                const userEnabledObj = countrySummaries.items.find(
                    (countrySummary: CountrySummaries) => countrySummary.id === id
                );
                userEnabled = userEnabledObj ? userEnabledObj.countryUserEnabled : null;
            }
        }
        if (allInteractedCreateCampaigns.includes(id)) {
            campaignCreateEnabled = allSelectedCreateCampaigns.includes(id);
        } else {
            if (!!countrySummaries) {
                const userCampaignCreatorObj = countrySummaries.items.find(
                    (userSummary: CountrySummaries) => userSummary.id === id
                );
                campaignCreateEnabled = userCampaignCreatorObj ? userCampaignCreatorObj.campaignCreateEnabled : null;
            }
        }
        if (allInteractedIsAdmin.includes(id)) {
            isCountryAdmin = allSelectedIsAdmin.includes(id);
        } else {
            if (!!countrySummaries) {
                const userAdminObj = countrySummaries.items.find(
                    (userSummary: CountrySummaries) => userSummary.id === id
                );
                isCountryAdmin = userAdminObj ? userAdminObj.countryAdmin : null;
            }
        }

        return {
            countryId:id,
            countryUserEnabled:userEnabled,
            isCountryAdmin,
            campaignCreateEnabled,
        };
    });
};
