import {
    Filters,
    CampaignSummary,
    CampaignListPage,
} from './campaignTypes';
import { SortDirection, TableCellTypes } from '../../components/Table/types';
import axios from 'axios';
import { DropdownValueType } from '../../components/base/Dropdowns/types';
import { downloadFileFromData } from '../../utils/helpers';
import { DELETE_CAMPAIGN } from './campaignQueries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getCookie } from '../../utils/cookie-storage';

export const campaignStatus = [
    {
        id:-1,
        name:'All',
        value: 'ALL'
    },
    {
        id:0,
        name:'NOT_STARTED',
        value: 'NOT_STARTED'
    },
    {
        id:1,
        name:'ACTIVE',
        value: 'ACTIVE'

    },
    {
        id:2,
        name:'CLOSED_COMPLETE',
        value: 'COMPLETED'


    },
    {
        id:3,
        name:'CLOSED_INCOMPLETE',
        value: 'CLOSED_INCOMPLETE'

    },
    {
        id:4,
        name:'ARCHIVED',
        value: 'ARCHIVED'

    }
];

export const setTableRows = (
    campainesListPage: CampaignListPage,
) => {
    return (
        campainesListPage &&
        campainesListPage.items.map((campaign: CampaignSummary) => {
            return {
                tableCells: [
                    {
                        cellName: campaign.campaignName,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.countryCode,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.framework,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.company,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.manager,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.members.toString(),
                        isCellSortable: false,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.status === 'COMPLETED' ? 'CLOSED_COMPLETE' : campaign.status,
                        isCellSortable: false,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: campaign.campaignName,
                        cellId: campaign.id,
                        isCellSortable: false,
                        cellType: 'delete' as TableCellTypes,
                        actionFunc: () => {}
                    }
                ]
            };
        })
    );
};

export const getFilters = (
    selectedCountry: string,
    selectedStatus: string,
    selectedFramework: string,
    allCountries:DropdownValueType[],
    allFrameworks:DropdownValueType[],
    setPage: (state: number) => void,
    setFilters: (state: Filters) => void
) => {
    const filter: Filters = {
        countryId: undefined,
        frameworkId: undefined,
        status: undefined
    };

    if(selectedStatus !== 'All') filter.status = selectedStatus;
    if(selectedFramework !== 'All') {
        const objectFound = allFrameworks.find(framework => framework.name === selectedFramework)
        if(objectFound) filter.frameworkId = objectFound.id;
    }

    if(selectedCountry !== 'All') {
        const objectFound = allCountries.find(country => country.value === selectedCountry);
        if(objectFound) filter.countryId = objectFound.id;
    }
    setPage(0);
    setFilters(filter);
};

export const getHeaderData = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'Campaign name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('campaignName')
    },
    {
        headerName: 'Country code',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('countryCode')
    },
    {
        headerName: 'Framework',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('frameworkName')
    },
    {
        headerName: 'Company',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('clientName')
    },
    {
        headerName: 'Manager',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('managerName')
    },
    {
        headerName: 'Members',
        isHeaderSortable: false
    },
    {
        headerName: 'Status',
        isHeaderSortable: false
    },
    {
        headerName: 'Edit',
        isHeaderSortable: false
    }
];

export const exportCampaignCSV = (setSpinner: Function, params: Filters) => {
    setSpinner(true);
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/campaign/summaries/csv',
            { countryCode: params.countryId, framework: params.frameworkId, status:params.status },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`, 'Campaigns Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};
