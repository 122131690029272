import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@mui/material';
import NewTable from '../../components/Table/Table';
import iconSave from '../../assets/images/icon-save.svg';
import iconUpload from '../../assets/images/icon-upload.svg';
import { TableRow as TableRowInter } from '../../components/Table/types';
import {
    RedButton,
    SearchField,
    DropdownSingleSelection,
    SuccessModal,
    ExportButton
} from '../../components';
import { FRAMEWORKS_LIST_MODEL, PROPOSITIONS, EDIT_FRAMEWORKS } from './frameworkQueries';
import {
    FrameworksListModel,
    FrameworksListPage,
    Filters,
    FrameworksSummary,
    FrameworkOrder,
    FrameworkMainProps,
} from './frameworkTypes';
import {
    getFilters,
    getHeaderData,
    saveHandler,
    setTableRows,
    disabledEnabledDropdownValues,
    exportFrameworksCSV
} from './frameworkHelpers';
import * as messages from '../../utils/Constant/messages';
import iconDownload from '../../assets/images/download-btn.svg';
import Router from '../../routes/router';
import { useParams } from 'react-router-dom';
import { UserStore } from '../../context/user-store';

const ManageFrameworks: React.FC = () => {
    const params = useParams<FrameworkMainProps>();
    const isFrameworkCreated = params.isFrameworkCreated ?? '';
    const isFrameworkDeleted = params.isFrameworkCreated === 'deleted' ? true : false;
    const { state: { user } } = useContext(UserStore);
    const perpage = 50;
    const [selectedBenchmarking, setBenchmarking] = useState<string>('All');
    const [selectedStatus, setStatus] = useState<string>('All');
    const [selectedProposition, setSelectedProposition] = useState<string>(
        'All'
    );

    const [page, setPage] = useState<number>(0);
    const [tableRow, setRow] = useState<TableRowInter[]>();
    const [filters, setFilters] = useState<Filters>({
        proposition: undefined,
        benchmarkingEnabled: undefined,
        frameworkEnabled: undefined
    });
    const [searchText, setSearchText] = useState<string>('');
    const [frameworksListPage, setFrameworksListPage] = useState<
        FrameworksListPage
    >();
    const [allSelectedBenchmarking, setAllSelectedBenchmarking] = useState<
        number[]
    >([]);
    const [allSelectedFrameworks, setAllSelectedFrameworks] = useState<
        number[]
    >([]);
    const [allInteractedBenchmarking, setAllInteractedBenchmarking] = useState<
        number[]
    >([]);
    const [allInteractedFrameworks, setAllInteractedFrameworks] = useState<
        number[]
    >([]);
    const [order, setOrder] = useState<FrameworkOrder>({
        field: 'name',
        isAsc: true
    });
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [exportSpinner, setExportSpinner] = useState<boolean>(false);

    useEffect(() => {
        if (isFrameworkDeleted) {
            setOpenSuccessModal(true);
        }
    }, [isFrameworkDeleted]);

    const [getFrameworks, { loading:loadingFrameworks }] = useLazyQuery<FrameworksListModel>(
        FRAMEWORKS_LIST_MODEL,
        {
            fetchPolicy: 'network-only',
            onCompleted: res => {
                setFrameworksListPage(res.frameworkSummaries);

                const allBenchmarking = res.frameworkSummaries.items
                    .filter(
                        (item: FrameworksSummary) =>
                            item.benchmarkingEnabled &&
                            !allInteractedBenchmarking.includes(
                                item.frameworkId
                            )
                    )
                    .map((item: FrameworksSummary) => item.frameworkId);
                const allUniqueSelectedBenchmarkingIds = Array.from(
                    new Set([...allBenchmarking, ...allSelectedBenchmarking])
                );
                setAllSelectedBenchmarking(allUniqueSelectedBenchmarkingIds);
                const allSelected = res.frameworkSummaries.items
                    .filter(
                        (item: FrameworksSummary) =>
                            item.frameworkEnabled &&
                            !allInteractedFrameworks.includes(item.frameworkId)
                    )
                    .map((item: FrameworksSummary) => item.frameworkId);
                const allUniqueSelectedIds = Array.from(
                    new Set([...allSelected, ...allSelectedFrameworks])
                );
                setAllSelectedFrameworks(allUniqueSelectedIds);
            },
            onError: err => {
                console.log(err);
            }
        }
    );
    const { data: dataPropositions } = useQuery(PROPOSITIONS, {
        fetchPolicy: 'cache-first'
    });
    const createSortHandler = (property: string) => {
        if (order && order.field === property) {
            setOrder({ field: property, isAsc: !order.isAsc });
        } else {
            setOrder({ field: property, isAsc: true });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const frameworksSearchCallback = (value: string) => {
        setSearchText(value);
        setPage(0);
    };

    const handleToggleBenchmarkingChange = (cellID:number) => {
        if (allSelectedBenchmarking.includes(cellID)) {
            const arrWithoutCell = allSelectedBenchmarking.filter(
                (selBench: number) => selBench !== cellID
            );
            setAllSelectedBenchmarking(arrWithoutCell);
        } else {
            setAllSelectedBenchmarking([...allSelectedBenchmarking, cellID]);
        }
        if (!allInteractedBenchmarking.includes(cellID)) {
            setAllInteractedBenchmarking([
                ...allInteractedBenchmarking,
                cellID
            ]);
        }
    };

    const handleToggleActivateChange = (cellID:number) => {
        if (allSelectedFrameworks.includes(cellID)) {
            const arrWithoutCell = allSelectedFrameworks.filter(
                (selCoun: number) => selCoun !== cellID
            );
            setAllSelectedFrameworks(arrWithoutCell);
        } else {
            setAllSelectedFrameworks([...allSelectedFrameworks, cellID]);
        }
        if (!allInteractedFrameworks.includes(cellID)) {
            setAllInteractedFrameworks([...allInteractedFrameworks, cellID]);
        }
    };

    useEffect(() => {
        // if (loading) {
        //     return undefined;
        // }
        getFrameworks({
            variables: {
                filters,
                search: searchText,
                pagination: {
                    order,
                    skip: page * perpage,
                    take: perpage
                }
            }
        });
    }, [page, filters, searchText, order]);

    useEffect(() => {
        if (!frameworksListPage) {
            return undefined;
        }
        const rows = setTableRows(
            frameworksListPage,
            handleToggleBenchmarkingChange,
            allSelectedBenchmarking,
            handleToggleActivateChange,
            allSelectedFrameworks
        );
        setRow(rows);
    }, [frameworksListPage,allSelectedBenchmarking,allSelectedFrameworks, openSuccessModal]);

    useEffect(() => {
        getFilters(
            setPage,
            setFilters,
            selectedBenchmarking,
            selectedStatus,
            selectedProposition
        );
    }, [selectedStatus, selectedBenchmarking, selectedProposition]);

    useEffect(() =>{
        if(isFrameworkCreated === 'true') {
        setOpenSuccessModal(true);
        }
    },[isFrameworkCreated]);

    const tablePagination = {
        rowsPerPage: perpage,
        currentPage: page,
        handleChangePage,
        totalCount: frameworksListPage && frameworksListPage.total
    };

    const headerData = getHeaderData(createSortHandler);

    const [editFrameworks] = useMutation(EDIT_FRAMEWORKS);

    const saveChanges = () => {
        saveHandler(
            allInteractedBenchmarking,
            allInteractedFrameworks,
            allSelectedBenchmarking,
            allSelectedFrameworks,
            editFrameworks,
            setOpenSuccessModal,
            setAllInteractedBenchmarking,
            setAllInteractedFrameworks,
            frameworksListPage
        );
    };

    const isDisabled = allInteractedBenchmarking.length === 0 && allInteractedFrameworks.length === 0;

    return (
        <>
            <MainWrapper>
                <LinkWrapper />
                <NameAndCTAs>
                    <h1>Frameworks</h1>
                    <SaveButton disabled={isDisabled} onClick={saveChanges}>
                        <img src={iconSave} alt="add frm icon" />
                        Save changes
                    </SaveButton>
                </NameAndCTAs>
                <FiltersAddAndSave>
                    <FiltersAndAdd>
                        <DropdownSingleSelection
                            labelText={'Proposition'}
                            isDisabled={false}
                            selectedValue={selectedProposition}
                            setValue={setSelectedProposition}
                            allValues={
                                dataPropositions &&
                                dataPropositions.frameworkPropositions && [
                                    { id: 0, name: 'All' },
                                    ...dataPropositions.frameworkPropositions.slice().sort().map(
                                        (item: string, i: number) => ({
                                            id: i + 1,
                                            name: item
                                        })
                                    )
                                ]
                            }
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText={'Benchmarking'}
                            isDisabled={false}
                            selectedValue={selectedBenchmarking}
                            setValue={setBenchmarking}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        <DropdownSingleSelection
                            labelText={'Status'}
                            isDisabled={false}
                            selectedValue={selectedStatus}
                            setValue={setStatus}
                            allValues={disabledEnabledDropdownValues}
                            width="10em"
                        />
                        { user.role === 'ADMIN' && 
                        <UploadButton  onClick={Router.goToUploadFrameworks}>
                        <img src={iconUpload} alt="upload" />
                        Framework Upload
                    </UploadButton>
                        }
                    </FiltersAndAdd>
                    <SearchAndSave>
                    {exportSpinner ? (
                            <CircularProgress />
                        ) : (
                            <ExportButton onClick={() => exportFrameworksCSV(setExportSpinner, filters)}>
                                <img src={iconDownload} alt="download" />
                            </ExportButton>
                        )}
                        <SearchField
                            placeholder="Search"
                            callback={(value: string) => frameworksSearchCallback(value)}
                        />
                    </SearchAndSave>
                </FiltersAddAndSave>
                <TableOrSplash>
                    {loadingFrameworks ? (
                        <CircularProgress />
                    ) : (
                        <NewTable
                            header={headerData}
                            tablePagination={tablePagination}
                            rows={tableRow}
                        />
                    )}
                </TableOrSplash>
            </MainWrapper>
            <SuccessModal
                openState={openSuccessModal}
                hideDuration={6000}
                setOpen={setOpenSuccessModal}
                message={isFrameworkDeleted ? messages.FRAMEWORKS_DELETE_FAILURE_MESSAGE : messages.FRAMEWORKS_SUCCESS_MESSAGE}
            />
        </>
    );
};

const UploadButton = styled(RedButton)`
    margin-left: 20px !important;
    width: 180px;
    img {
        padding-right: 7px;
    }
    height: 58%;
    margin-top: 1.9em !important;
`;

const SaveButton = styled(RedButton)`
    margin-left: 10px !important;
    width: 180px;
    font-size: 16px !important;
    img {
        padding-right: 3px;
    }
    height: 50%;
    margin-top: 0.67em !important;
`;

const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NameAndCTAs = styled('div')`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 5px 0;

    h2 {
        margin: 0;
    }
`;

const FiltersAddAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const FiltersAndAdd = styled('div')`
    display: flex;
`;

const SearchAndSave = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const TableOrSplash = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LinkWrapper = styled('div')`
    height: 46px;
`;

export default ManageFrameworks;
