import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { resolvers, typeDefs } from './resolvers';
import { setContext } from 'apollo-link-context';
import { parseJwt } from '../../utils/local-storage';
import { gql } from 'apollo-boost';
import { deleteAuthCookies, getCookie } from '../../utils/cookie-storage';

export const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: window.__ENV__.REACT_APP_APP_URL + '/graphql'
});


function validateJwtNotExpired(){
    const token = getCookie('pid-token');
    if (token) {
        const jwt = parseJwt(token);
        const current_time = Date.now() / 1000;
        return jwt.exp >= current_time + 5;
    }
    return false;
}

function shouldContextBeCreated(operationName: string): boolean {
    // List of GQL request that should not require creating context
    const operationNames = ['addJwtToWhitelist', 'removeJwtFromWhitelist'];
    return operationNames.indexOf(operationName) < 0;
}
const authLink = setContext(async (operation, { headers }) => {
    if (
        operation.operationName &&
        !shouldContextBeCreated(operation.operationName)
    ) {
        return { ...headers };
    }
    const token = getCookie('pid-token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    link: authLink.concat(httpLink as any) as any,
    typeDefs,
    resolvers
});

export async function addJwtToWhitelist(jwt?: string, accessToken?: string): Promise<void> {
    const origin = "admin";
    if (jwt && accessToken) {
        await client.mutate({
            mutation: gql`
                mutation addJwtToWhitelist($jwt: String!, $origin: String!, $accessToken: String!) {
                    addJwtToWhitelist(jwt: $jwt, origin: $origin, accessToken: $accessToken) {
                        message
                    }
                }
            `,
            variables: { jwt, origin, accessToken }
        });
    }
}

export async function removeJwtFromWhitelist(auth?: any): Promise<void> {
    const jwt = getCookie('pid-token');
    if (jwt&&validateJwtNotExpired()) {
        await client.mutate({
            mutation: gql`
                mutation removeJwtFromWhitelist($jwt: String!) {
                    removeJwtFromWhitelist(jwt: $jwt) {
                        message
                    }
                }
            `,
            variables: { jwt }
        });
    }
    localStorage.clear();
    deleteAuthCookies();
    sessionStorage.clear();
}

export default client;
