import {
    Filters,
    FrameworksListPage,
    FrameworksSummary
} from './frameworkTypes';
import { SortDirection, TableCellTypes } from '../../components/Table/types';
import Router from '../../routes/router';
import axios, { AxiosError } from 'axios';
import { downloadFileFromData } from '../../utils/helpers';
import { MutationFunction } from '@apollo/client';
import { getCookie } from '../../utils/cookie-storage';

const handleCTA = (id: number, name: string) => {
    Router.goToFramework({ frameworkId: id.toString(), frameworkName: name });
};

export const TabOptions = {
    COUNTRIES: 'Countries',
    COMPANIES: 'Companies',
    SETTINGS: 'Settings'
};

export const tabLabels = [TabOptions.COUNTRIES, TabOptions.COMPANIES, TabOptions.SETTINGS];

export const setTableRows = (
    frameworksListPage: FrameworksListPage,
    handleToggleBenchmarkingChange:(cellID: number) => void,
    allSelectedBenchmarking:number[],
    handleToggleActivateChange:(cellID: number) => void,
    allSelectedFrameworks:number[]
) => {
    return (
        frameworksListPage &&
        frameworksListPage.items.map((frm: FrameworksSummary) => {
            return {
                tableCells: [
                    {
                        cellName: frm.name,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: frm.countryOwner,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        cellName: frm.proposition,
                        isCellSortable: true,
                        cellType: 'string' as TableCellTypes
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'ON',
                            falseLabel: 'OFF'
                        },
                        currentState: allSelectedBenchmarking.includes(frm.frameworkId),
                        actionFunc: () => handleToggleBenchmarkingChange(frm.frameworkId),
                        cellID: frm.frameworkId
                    },
                    {
                        isCellSortable: false,
                        cellType: 'boolean' as TableCellTypes,
                        toggleLabels: {
                            trueLabel: 'Enabled',
                            falseLabel: 'Disabled'
                        },
                        currentState: allSelectedFrameworks.includes(frm.frameworkId),
                        actionFunc: () => handleToggleActivateChange(frm.frameworkId),
                        cellID: frm.frameworkId
                    },
                    {
                        isCellSortable: false,
                        cellType: 'action' as TableCellTypes,
                        actionFunc: () => handleCTA(frm.frameworkId, frm.name)
                    }
                ]
            };
        })
    );
};

export const getFilters = (
    setPage: (state: number) => void,
    setFilters: (state: Filters) => void,
    selectedBenchmarking: string,
    selectedStatus: string,
    selectedProposition: string
) => {
    const filter: Filters = {
        benchmarkingEnabled: undefined,
        frameworkEnabled: undefined,
        proposition: undefined
    };

    filter.frameworkEnabled =
        selectedStatus === 'Disabled'
            ? false
            : selectedStatus === 'Enabled'
            ? true
            : undefined;
    filter.benchmarkingEnabled =
        selectedBenchmarking === 'Disabled'
            ? false
            : selectedBenchmarking === 'Enabled'
            ? true
            : undefined;
    filter.proposition =
        selectedProposition === 'All' ? undefined : selectedProposition;

    setPage(0);
    setFilters(filter);
};

export const getHeaderData = (
    createSortHandler: (property: string) => void
) => [
    {
        headerName: 'Framework name',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('name')
    },
    {
        headerName: 'Country owner',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('countryOwner')
    },
    {
        headerName: 'Propositions',
        isHeaderSortable: true,
        sortDirection: 'asc' as SortDirection,
        sortingFunc: () => createSortHandler('proposition')
    },
    {
        headerName: 'Benchmarking',
        isHeaderSortable: false,
        sortDirection: 'asc' as SortDirection
    },
    {
        headerName: 'Status',
        isHeaderSortable: false
    },
    {
        headerName: 'View',
        isHeaderSortable: false
    }
];

const editFrameworksDTO = (
    allInteractedBenchmarking: number[],
    allInteractedFrameworks: number[],
    allSelectedFrameworks: number[],
    allSelectedBenchmarking: number[],
    frameworksListPage?: FrameworksListPage
) => {
    const mergedArray = [...allInteractedBenchmarking, ...allInteractedFrameworks];
    const allUniqueInteractedRows = Array.from(new Set(mergedArray));

    return allUniqueInteractedRows.map((row: number) => {
        let frameworkEnabledValue;
        let benchmarkingEnabled;

        if (allInteractedFrameworks.includes(row)) {
            frameworkEnabledValue = allSelectedFrameworks.includes(row);
        } else {
            if (!!frameworksListPage) {
                const frameworkEnabledObj = frameworksListPage.items.find((frm: FrameworksSummary) => frm.frameworkId === row);
                frameworkEnabledValue = frameworkEnabledObj ? frameworkEnabledObj.frameworkEnabled : null;
            }
        }
        if (allInteractedBenchmarking.includes(row)) {
            benchmarkingEnabled = allSelectedBenchmarking.includes(row);
        } else {
            if (!!frameworksListPage) {
                const benchmarkingObj = frameworksListPage.items.find((frm: FrameworksSummary) => frm.frameworkId === row);
                benchmarkingEnabled = benchmarkingObj ? benchmarkingObj.benchmarkingEnabled : null;
            }
        }
        return {
            id: row,
            frameworkEnabled: frameworkEnabledValue,
            benchmarkingEnabled
        };
    });
};

export const versionChanges = (version: string) => {
    const versionParts = version.split('.').map(Number);
    if (versionParts[1] === 9) {
        versionParts[0]++;
        versionParts[1] = 0;
    } else {
        versionParts[1]++;
    }
    return versionParts.join('.');
};

export const saveHandler = (
    allInteractedBenchmarking: number[],
    allInteractedFrameworks: number[],
    allSelectedBenchmarking: number[],
    allSelectedFrameworks: number[],
    editFrameworks: MutationFunction,
    setOpenSuccessModal: (state: boolean) => void,
    setAllInteractedBenchmarking: (benchmarkingIds: number[]) => void,
    setAllInteractedFrameworks: (frameworkIds: number[]) => void,
    frameworksListPage?: FrameworksListPage
) => {
    if (allInteractedBenchmarking.length === 0 && allInteractedFrameworks.length === 0) {
        return;
    }

    editFrameworks({
        variables: {
            data: editFrameworksDTO(
                allInteractedBenchmarking,
                allInteractedFrameworks,
                allSelectedFrameworks,
                allSelectedBenchmarking,
                frameworksListPage
            )
        }
    })
        .then(() => {
            setAllInteractedBenchmarking([]);
            setAllInteractedFrameworks([]);
            setOpenSuccessModal(true);
        })
        .catch((err: AxiosError) => {
            console.log(err);
        });
};

export const disabledEnabledDropdownValues = [
    {
        id: 0,
        name: 'All'
    },
    {
        id: 1,
        name: 'Disabled'
    },
    {
        id: 2,
        name: 'Enabled'
    }
];

export const exportFrameworksCSV = (setSpinner: Function, params: Filters) => {
    setSpinner(true);
    const tkn = getCookie('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .post(
            window.__ENV__.REACT_APP_APP_URL + '/admin/framework/summaries/csv',
            { proposition: params.proposition, benchmarkingEnabled: params.benchmarkingEnabled, frameworkEnabled:params.frameworkEnabled },
            {
                headers: {
                    'pearl-client-uuid': clientUUID,
                    Authorization: `Bearer ${tkn}`
                }
            }
        )
        .then((response) => {
            setSpinner(false);
            downloadFileFromData(`data:text/csv;charset=utf-8,${response.data}`, 'Frameworks Report.csv');
        })
        .catch((error) => {
            setSpinner(false);
            console.log(error);
        });
};

export const createTempCountryUserEnabled = (
    allSelectedCountryUserEnabled: number[],
    allInteractedCountryUserEnabled: number[]
) => {
    return allInteractedCountryUserEnabled.map((countryId: number) => {
        return {
            id:countryId,
            frameworkEnabled: allSelectedCountryUserEnabled.includes(countryId)
        };
    });
};
