import Cookies from 'js-cookie';

type cookieAttr = {
    expires?: number | Date | undefined;
    path?: string | undefined, domain?: string | undefined;
    secure?: boolean | undefined;
    sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None' | undefined;
}

export const getCookie = (name: string) => {
    const result = Cookies.get(name);
    if (result === null) {
        return undefined;
    }
    return result;
};

export const setCookie = (name: string, value?: string, cookieVal?: cookieAttr) => {
    if (value !== undefined) {
        Cookies.set(name, value, cookieVal);
    }
};

export const deleteCookie = (name: string) => {
    Cookies.remove(name);
};

export const deleteAuthCookies = () => {
    deleteCookie('pid-access-token');
    deleteCookie('pid-token');
};
